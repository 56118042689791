import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { sort } from 'semver'
import { ErrorDisplay } from 'pages/app/components'
import { useGatewayReleases, useMySites } from 'pages/settings/pages/orgs-sites'
import { Button, DynamicTable, Spinner, Text } from 'src/components/ui'
import { useNavigationContext } from 'src/contexts/navigation'
import { GatewayRelease } from 'src/services'
import { isDefined, SiteRole } from 'src/types'
import { IntelecyIcon } from 'src/layout/IntelecyIcon'
import { downloadsTableConfig } from './downloads.config'

export function DownloadsPage(): JSX.Element {
  const { setTitleComponent } = useNavigationContext()
  const gatewayReleasesQuery = useGatewayReleases()
  const sitesQuery = useMySites()
  useEffect(() => {
    setTitleComponent(
      <Text variant="title" bold>
        Downloads
      </Text>,
    )
    return () => setTitleComponent(null)
  }, [setTitleComponent])

  if (gatewayReleasesQuery.isLoading || sitesQuery.isLoading) {
    return <Spinner />
  }

  if (gatewayReleasesQuery.isError || sitesQuery.isError) {
    const errorQuery = gatewayReleasesQuery.isError
      ? gatewayReleasesQuery
      : sitesQuery
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Failed to fetch data"
        action={errorQuery.refetch}
      />
    )
  }

  const hasAdminAccess = sitesQuery.data.some(
    s => s.viewerRole === SiteRole.ADMIN,
  )
  if (!hasAdminAccess) return <AccessRestricted />

  return (
    <div className="m-s mt-0 flex flex-col gap-s rounded-xs bg-background p-s">
      <div className="flex flex-col gap-3xs">
        <Text bold>Gateway Installers</Text>
        <Text variant="description">
          For detailed instructions use{' '}
          <a
            href="https://intelecy.notion.site/Intelecy-Gateway-Install-Guide-a1b351d3e9134f9297844e0bf61627b3"
            target="_blank"
            rel="noreferrer"
            className="text-text-success-secondary no-underline"
          >
            Gateway Install Guide
          </a>
        </Text>
      </div>

      <div className="flex h-full flex-1 flex-col">
        <GatewayReleaseTable gatewayReleases={gatewayReleasesQuery.data} />
      </div>
    </div>
  )
}

function GatewayReleaseTable({
  gatewayReleases,
}: {
  gatewayReleases: GatewayRelease[]
}): JSX.Element {
  function handleDownloadClick(id: string): void {
    const release = gatewayReleases.find(r => r.id === id)
    if (release) {
      const a = document.createElement('a')
      a.href = release.url
      a.download = release.summary
      a.click()
      toast.success('Download started', { position: 'top-right' })
    } else toast.error('Failed to download release', { position: 'top-right' })
  }

  const sortedReleases = sort(gatewayReleases.map(r => r.semver))
    .map(v => gatewayReleases.find(r => r.semver === v))
    .filter(isDefined)
    .reverse()

  return (
    <DynamicTable
      className="!h-[350px] !w-full"
      id="gateway-releases"
      data={sortedReleases}
      config={downloadsTableConfig(handleDownloadClick)}
    />
  )
}

function AccessRestricted(): JSX.Element {
  const navigate = useNavigate()
  return (
    <div className="flex h-full flex-1 flex-col items-center justify-center gap-m">
      <IntelecyIcon size="48px" />
      <div className="flex max-w-[323px] flex-col gap-2xs text-center">
        <Text variant="title" bold>
          Access Restricted
        </Text>
        <Text variant="title">
          You currently don't have permission to this page. Please contact your
          site administrator to request access.
        </Text>
      </div>
      <Button
        variant="icon-primary"
        icon={light('arrow-left')}
        title="Back to Dashboard"
        onClick={() => {
          navigate('/')
        }}
      />
    </div>
  )
}
