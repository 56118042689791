import { Navigate, Routes, Route } from 'react-router-dom'
import { useAuth } from 'src/contexts/auth'
import { MainLayout } from 'src/layout'
import { DocsPage, DownloadsPage, NotFoundPage } from 'pages/app'
import { SettingsRouter } from 'pages/settings'
import { SiteRouter } from 'pages/site'

export function AppRouter(): JSX.Element {
  // redirects should always use the default factory id
  const {
    viewer: { defaultFactoryId },
  } = useAuth()
  const rootLink = `/site/${defaultFactoryId}`

  function RedirectRoute({ path }: { path: string }): JSX.Element {
    return (
      <Route path={path} element={<Navigate to={`${rootLink}${path}`} />} />
    )
  }

  return (
    <Routes>
      <Route path="docs/*" element={<DocsPage />} />
      <Route element={<MainLayout />}>
        <Route path="downloads" element={<DownloadsPage />} />
        <Route path="/settings/*" element={<SettingsRouter />} />
        <Route path="/site/:siteId/*" element={<SiteRouter />} />
      </Route>

      {/* Redirects from the old, non-site paths */}
      {/* These are also hit when using the menu from the settings pages */}
      {RedirectRoute({ path: '' })}
      {RedirectRoute({ path: '/trend' })}
      {RedirectRoute({ path: '/trend-beta' })}
      {RedirectRoute({ path: '/models' })}
      {RedirectRoute({ path: '/forecast' })}
      {RedirectRoute({ path: '/anomalies' })}
      {RedirectRoute({ path: '/assets' })}
      {RedirectRoute({ path: '/labs' })}
      {RedirectRoute({ path: '/data-explorer' })}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
