import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import MenuWrapper from 'react-popper-tooltip'
import { useEffect, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { debounce } from 'lodash'
import { Checkbox, Icon, NumberInput, Text } from 'src/components/ui'
import classNames from 'classnames'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { ChartOptions, SeriesOptions } from 'src/types/chartTypes'
import { seriesIdToString } from 'src/contexts/charts'
import { ChartData } from '../../useChartData'
import { Markers, SeriesConfig, SettingsAccordion } from './chart-settings'

// TODO: Remove this when we release without the feature flag
function SettingsAccordionWrapper({
  label,
  isOpen,
  setIsOpen,
  children,
}: {
  label: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: JSX.Element
}): JSX.Element {
  const { trendMarkers } = useFlags()
  if (!trendMarkers) return children
  return (
    <SettingsAccordion label={label} isOpen={isOpen} setIsOpen={setIsOpen}>
      {children}
    </SettingsAccordion>
  )
}

interface MenuProps {
  chart: ChartOptions
  data: ChartData[]
  setOptions: (index: number, options: Partial<SeriesOptions>) => void
  setChart: (options: ChartOptions) => void
  isModal?: boolean
}

export default function GraphSettings({
  chart,
  data,
  setOptions,
  setChart,
  isModal,
}: MenuProps): JSX.Element {
  const [isAxisOpen, setIsAxisOpen] = useState(true)
  const [isMarkersOpen, setIsMarkersOpen] = useState(false)
  const [isSeriesOpen, setIsSeriesOpen] = useState(true)
  const [selected, setSelected] = useState(chart.commonY ?? false)
  const [localChart, setLocalChart] = useState(chart)
  const { trendMarkers } = useFlags()

  const debouncedSetChart = useMemo(
    () => debounce(options => setChart(options), 500),
    [setChart],
  )

  useEffect(() => {
    setLocalChart(chart)
  }, [chart])

  useEffect(() => {
    setIsMarkersOpen(!!chart.commonY)
  }, [chart.commonY])

  return (
    <MenuWrapper
      placement="left"
      trigger="click"
      tooltip={({ tooltipRef: ref, getTooltipProps }) => (
        <div
          className={classNames(
            'max-h-[350px] overflow-y-auto rounded-2xs border border-solid border-border bg-background shadow-lg',
            trendMarkers
              ? 'flex flex-col gap-xs min-w-[281px] px-xs py-s'
              : 'px-m py-xs',
          )}
          {...getTooltipProps({
            ref,
            style: {
              zIndex: isModal ? zIndex.modalLegendMenu : zIndex.trendLegendMenu,
            },
          })}
        >
          <Text variant={trendMarkers ? 'content' : 'description'} bold>
            Chart Settings
          </Text>

          <SettingsAccordionWrapper
            label="Axis Settings"
            isOpen={isAxisOpen}
            setIsOpen={setIsAxisOpen}
          >
            <div
              className={classNames(
                'flex flex-col gap-xs',
                !trendMarkers && 'py-xs',
              )}
            >
              <div className="flex items-center gap-2xs">
                <Checkbox
                  value={selected}
                  onChange={val => {
                    setSelected(val)
                    setChart({ ...chart, commonY: val })
                  }}
                />
                <Text variant="small" bold>
                  Common Y Axis
                </Text>
              </div>
              {(selected || trendMarkers) && (
                <div
                  className={classNames(
                    'flex items-center justify-between',
                    !trendMarkers && 'pt-xs',
                  )}
                >
                  <div className="flex w-[110px] items-center gap-xs">
                    <Text variant="small" bold>
                      Min:
                    </Text>
                    <NumberInput
                      disabled={!selected}
                      value={localChart.min}
                      allowUndefined
                      onChange={val => {
                        setLocalChart({ ...localChart, min: val })
                        debouncedSetChart({ ...chart, min: val })
                      }}
                      placeholder="Auto"
                    />
                  </div>

                  <div className="flex w-[110px] items-center gap-xs">
                    <Text variant="small" bold>
                      Max:
                    </Text>
                    <NumberInput
                      disabled={!selected}
                      value={chart.max}
                      allowUndefined
                      onChange={val => {
                        debouncedSetChart({ ...chart, max: val })
                      }}
                      placeholder="Auto"
                    />
                  </div>
                </div>
              )}
            </div>
          </SettingsAccordionWrapper>

          {trendMarkers && (
            <SettingsAccordion
              disabled={!selected}
              label="Markers"
              isOpen={isMarkersOpen}
              tooltip={!selected ? 'Enable common Y-axis' : undefined}
              setIsOpen={setIsMarkersOpen}
              isModal={isModal}
            >
              <Markers chart={chart} setChart={setChart} />
            </SettingsAccordion>
          )}

          <SettingsAccordionWrapper
            label="Tag details"
            isOpen={isSeriesOpen}
            setIsOpen={setIsSeriesOpen}
          >
            <>
              {data.map((data, index) => {
                const seriesConfig = chart.data[index]
                return (
                  <SeriesConfig
                    key={seriesIdToString(seriesConfig)}
                    data={data}
                    series={seriesConfig}
                    minDisabled={!!chart.min || chart.commonY}
                    maxDisabled={!!chart.max || chart.commonY}
                    colorIndex={chart.colorIndex ?? chart.id + index}
                    setOptions={options => setOptions(index, options)}
                  />
                )
              })}
            </>
          </SettingsAccordionWrapper>
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef: ref }) => (
        <div
          className="mx-xs flex w-[100px] cursor-pointer items-center gap-2xs leading-[0]"
          {...getTriggerProps({ ref })}
        >
          <Icon icon={light('gear')} />
          <Text variant="description" bold>
            Chart Settings
          </Text>
        </div>
      )}
    </MenuWrapper>
  )
}
